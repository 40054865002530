import React, { useState, useEffect } from 'react';

import Upload from './pages/upload'
import Thanks from './pages/thanks'


function App() {
  const [complete, setComplete] = useState(false)

  useEffect(() => {}, [complete])

  return (
    <div>
      {!complete ? <Upload setComplete={setComplete} /> : <Thanks />}
    </div>
  );
}

export default App;