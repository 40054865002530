import React, { useState, useEffect } from 'react';

import aia_icon from '../assets/img/logo.png';

function Upload() {
    return (
        <div className="App h-screen flex flex-col">
            <div className="container w-4/12 mx-auto flex-grow flex flex-col items-center">


                <div className="w-full h-[200px] mt-[20px] mx-auto flex flex-col items-center">
                    <img src={aia_icon} className="w-[80px]" />
                </div>

                <div className="w-full mx-auto flex flex-col items-center">
                    <h1 className="text-[80px] font-montserrat font-bold text-[#322B52] mb-[40px]">
                        Thank you!
                    </h1>
                    <p className="font-montserrat text-base text-[#322B52] mb-[5px]"><b>Congratulations!</b></p>
                    <p className="font-montserrat text-base text-[#322B52]">You successfully finished the process and we collected your data.</p>
                </div>

                <button className="bg-[#D71D50] mt-[50px] w-[300px] text-white font-bold py-2 px-4 rounded-full drop-shadow-xl">
                    Share Feedback
                </button>

            </div>
            <p className="mb-4 self-center text-[14px] text-gray-500">Powered by <b>Qumata</b></p>
        </div>
    );
}

export default Upload;