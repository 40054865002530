import React, { useState, useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';
import { Line } from 'rc-progress';

import upload_icon from '../assets/img/upload_icon.png';
import aia_icon from '../assets/img/logo.png';


function Upload({ setComplete }) {
    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        accept: ".zip"
    });

    const [upload, setUpload] = useState(false)
    const [percentage, setPercentage] = useState(0);
    const [intervalId, setIntervalId] = useState(null);

    const addRandomValue = () => {
        const randomValue = Math.floor(Math.random() * 10) + 1;
        setPercentage(prevPercentage => prevPercentage + randomValue);
    };

    const handleClick = () => {
        if (intervalId) return;

        setUpload(true)
        const id = setInterval(addRandomValue, 300);
        setIntervalId(id);
    };

    useEffect(() => {
        if (percentage >= 100) {
            clearInterval(intervalId);
            setIntervalId(null);
            setPercentage(100);
            setComplete(true)
        }
    }, [percentage, intervalId]);

    return (
        <div className="App h-screen flex flex-col">
            <div className="container w-4/12 mx-auto mt-[100px] flex-grow flex flex-col">

                <div class="absolute top-[10px] right-[10px]">
                    <img src={aia_icon} className="w-[60px]" />
                </div>

                <div className="mb-8 text-[#4f3939]">
                    <h1 className="text-3xl font-montserrat font-bold">
                        Upload your data
                    </h1>
                </div>

                <div className="mb-[50px] text-[#4f3939] font-medium">
                    <h4 className="font-montserrat text-base">
                        Send your data via the web with an upload feature
                    </h4>
                </div>

                <div className="shadow-[0px_0px_60px_1px_rgba(0,0,0,0.2)] px-24 py-12 flex flex-col items-center rounded-md">

                    <div className="border-2 border-dashed border-red-300 bg-red-50 w-full min-h-[300px] min-w-[300px] rounded-md flex flex-col items-center justify-center">
                        <div className="flex flex-col items-center">
                            <img src={upload_icon} className="w-[80px]" />
                            <p className="font-bold mt-[18px]">Drag & drop files or
                                &nbsp;
                                <a href="#" className="text-[#913f3f] underline" onClick={() => openFileSelector()}>Browse</a>
                            </p>
                            <p className="text-[14px] mt-[6px]">Supported format: ZIP</p>
                        </div>
                    </div>

                    <div className="pt-10 w-full flex flex-col items-center">



                        {filesContent.map((file, index) => (
                            <div className="pb-[20px]" key={file.name}>
                                <h2>{file.name}</h2>
                            </div>
                        ))}

                        {upload && <><Line className="pb-[50px]" percent={percentage} strokeWidth={2} strokeColor="#913f3f" /></>}

                        {filesContent.length === 0
                            ?
                            <button
                                className="bg-[#ee8989] hover:bg-[#c57272] text-white font-bold py-2 px-4 rounded-full w-full min-w-[300px] drop-shadow-xl"
                                onClick={() => openFileSelector()}
                            >
                                Select File
                            </button>
                            :
                            <button
                                className="bg-[#ee8989] hover:bg-[#c57272] text-white font-bold py-2 px-4 rounded-full w-full min-w-[300px] drop-shadow-xl"
                                onClick={() => handleClick()}
                            >
                                Upload
                            </button>
                        }

                    </div>
                </div>

            </div>
            <p className="mb-4 self-center text-[14px] text-gray-500">Powered by <b>Qumata</b></p>

        </div >
    );
}

export default Upload;